import { computed, effect, Injectable } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

import { CommonService } from '../../services/common.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  // disabling because this is just one big array of items and cannot be broken down further
  // eslint-disable-next-line max-lines-per-function
  public menuItems = computed(() => [
    {
      label: 'Dashboard',
      icon: 'pi pi-th-large',
      visible: true,
      routerLink: '/app/dashboard',
      moduleName: 'Dashboard',
      disabled: false,
      tooltipOptions: {
        tooltipLabel: 'Dashboard',
      },
    },
    {
      label: this.authService.userRoleName().includes('AGENT')
        ? 'My Work Queue'
        : 'Work Queue',
      icon: 'pi pi-list',
      visible: true,
      moduleName: 'Work Queue',
      expanded: false,
      routerLink: '/app/patient-encounters/work-queue',
      tooltipOptions: {
        tooltipLabel: this.authService.userRoleName().includes('AGENT')
          ? 'My Work Queue'
          : 'Work Queue',
      },
    },
    {
      label: 'Outamate Works',
      icon: 'pi pi-fw pi-bars',
      visible: false,
      expanded: false,
      items: [
        {
          label: 'Workflow Components',
          routerLink: 'app/workflow-management/workflows',
          visible: true,
        },
        {
          label: 'Outamate Works Designer',
          target: '_blank',
          routerLink: environment.WORKFLOW_URL,
          visible: true,
        },
      ],
      tooltipOptions: {
        tooltipLabel: 'Outamate Works',
      },
    },
    {
      label: 'Reports',
      icon: 'pi pi-file',
      visible: true,
      routerLink: '/app/reports',
      tooltipOptions: {
        tooltipLabel: 'Reports',
      },
    },
    {
      label: 'User Management',
      icon: 'pi pi-users',
      moduleName: 'User Management',
      visible:
        this.authService.userRoleName().includes('SUPER_ADMIN') ||
        this.authService.userRoleName().includes('MANAGER'),
      routerLink: '/app/user-management',
      tooltipOptions: {
        tooltipLabel: 'User Management',
      },
    },
  ]);
  selectedMenu: any;

  constructor(
    private router: Router,
    public commonService: CommonService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    effect(
      () => {
        this.getActiveMenu();
      },
      { allowSignalWrites: true }
    );
  }

  getActiveMenu(items?: any) {
    if (!items) {
      items = this.menuItems();
    }

    const rt = this.commonService.getActivatedRouteChild(this.route);
    rt.data.subscribe((data: Data) => {
      this.setActiveMenu(items, data);
    });

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.commonService.getActivatedRouteChild(this.route);
        rt.data.subscribe((data: Data) => {
          this.setActiveMenu(items, data);
        });
      });
  }

  private setActiveMenu(items?: any, data?: any) {
    const { moduleName } = data;

    if (moduleName) {
      items.forEach((item: any) => {
        if (item.label === moduleName) {
          this.selectedMenu = item;
          return;
        }
      });
    }
  }
}
