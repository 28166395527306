import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

import { UserInfo } from '../models/user';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private userInfoSubject = new ReplaySubject<UserInfo | null>(1);
  userInfo$ = this.userInfoSubject.asObservable();

  constructor(private authService: AuthService) {
    this.loadUserInfo();
  }

  private loadUserInfo() {
    this.authService.Authenticated().subscribe({
      next: (userInfo) => {
        this.userInfoSubject.next(userInfo);
      },
    });
  }

  getUserInfo(): Observable<UserInfo | null> {
    return this.userInfo$;
  }
}
