import { FormGroup } from '@angular/forms';
import { AnyObject } from '@outa-works/models';
import { KeyFilterPattern } from 'primeng/keyfilter';

export type SelectOption = { label: string; value: any; [key: string]: any };

type BaseDynamicField = {
  group?: string;
  className?: string;
  uid: string;
  key: string;
  label: string;
  value: any;
  required?: boolean;
  minVal?: any;
  maxVal?: any;
  minLength?: number;
  maxLength?: number;
  regex?: RegExp | KeyFilterPattern; // TODO: fix custom regex, not working in pKeyFilter
  jsonPath: string;
  noOnlyWhiteSpace?: boolean;
  noWhiteSpace?: boolean;
  capitalize?: boolean;
  trim?: boolean;
  tooltip?: string;
  tooltipPosition?: 'right' | 'left' | 'top' | 'bottom';
  tooltipEvent?: 'hover' | 'focus';
};

type DynamicTextField = BaseDynamicField & {
  inputType: 'text' | 'textarea';
};

type DynamicNumberField = BaseDynamicField & {
  inputType: 'number';
  dataFormat: 'currency' | 'percent' | 'int' | 'decimal';
};

export type DropdownField = BaseDynamicField & {
  lookupSrc?: string;
  lookupId?: string;
  options?: Array<any>;
  optionLabel?: string;
  optionValue?: string;
};

type DynamicDropdownField = DropdownField & {
  inputType: 'select';
};

type DynamicMultiSelectField = DropdownField & {
  inputType: 'multi-select';
};

type DynamicDateField = BaseDynamicField & {
  inputType: 'date';
};

type DynamicTimeField = BaseDynamicField & {
  inputType: 'time';
};

type DynamicSwitchField = BaseDynamicField & {
  inputType: 'switch';
};

type DynamicHiddenField = BaseDynamicField & {
  inputType: 'hidden';
};

export type DynamicArrayField = BaseDynamicField & {
  inputType: 'array';
  supersetJsonPath?: string;
  filters?: Array<{
    key: string;
    value: string | number;
  }>;
  items: {
    // eslint-disable-next-line no-use-before-define
    properties: DynamicField[];
  };
};

type DynamicPrimitiveArrayField = BaseDynamicField & {
  inputType: 'primitive-array';
  // eslint-disable-next-line no-use-before-define
  items: DynamicField;
};

export type DynamicField =
  | DynamicTextField
  | DynamicNumberField
  | DynamicDropdownField
  | DynamicDateField
  | DynamicMultiSelectField
  | DynamicTimeField
  | DynamicSwitchField
  | DynamicHiddenField
  | DynamicPrimitiveArrayField
  | DynamicArrayField;

export type DynamicFormOutput = {
  formData: AnyObject<any>;
  form: FormGroup;
  formFields: DynamicField[];
};
