import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnyObject } from '@outa-works/models';
import { MenuItem, MessageService } from 'primeng/api';

import { DateService } from './date.service';
import { LoggerService as logger } from './logger.service';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private CASE_STEPS = [
    {
      stepId: 1,
      stepName: 'Title Review',
    },
    {
      stepId: 2,
      stepName: 'Title Requested',
    },
    {
      stepId: 3,
      stepName: 'Referral Received',
    },
    {
      stepId: 4,
      stepName: 'FHA Letter',
    },
  ];

  constructor(
    private router: Router,
    private messageService: MessageService,
    private dateService: DateService,
    private authService: AuthService
  ) {}

  get CurrentUserRoleId() {
    return this.authService.Profile;
  }

  get CurrentUserId() {
    return this.authService.UserId;
  }

  getCaseSteps() {
    return [...this.CASE_STEPS];
  }

  /**
   * @description Validate `FormGroup` for any errors
   * @param {FormGroup} form
   * @returns {boolean} isValid
   */
  validateForm(form: FormGroup) {
    const invalidArr: string[] = [];
    const isValid = this.validateFormHandler(form, invalidArr);
    if (!isValid) {
      document
        .querySelector(`[formControlName=${invalidArr[0]}].ng-invalid`)
        ?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
    }

    if (!isValid) {
      logger.error(`${invalidArr.join(', ')} is invalid`);
    }
    return isValid;
  }

  private validateFormHandler(form: FormGroup, invalidArr: string[] = []) {
    if (form.valid) return true;

    Object.entries(form.controls).forEach(([key, control]) => {
      if (control instanceof FormArray) {
        control.controls.forEach((arrControl, i) => {
          if (arrControl instanceof FormGroup) {
            this.validateFormHandler(arrControl, invalidArr);
          } else if (arrControl.invalid) {
            invalidArr.push(`${key}-${i}`);
            arrControl.markAsDirty();
            arrControl.updateValueAndValidity({ onlySelf: true });
          }
        });
      } else if (control instanceof FormGroup) {
        this.validateFormHandler(control, invalidArr);
      } else if (control.invalid) {
        invalidArr.push(key);
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });

    return false;
  }

  /**
   * @description Filter null-ish values from the object
   * @param data JSON value - **Pass by reference!**
   * @param emptyObjToNull Empty's out properties of an object - result: {}
   * @param deleteParentKey Removes parent key of an object if no properties are present
   */
  // eslint-disable-next-line max-lines-per-function
  filterPayload(
    data: { [key: string]: any },
    trimValues = false,
    formatDate = false,
    format = 'YYYY-MM-DD',
    emptyObjToNull = false,
    deleteParentKey = true
  ) {
    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value) {
        const isDate = this.dateService.isValid(value);

        if (Array.isArray(value)) {
          value.forEach((data) =>
            this.filterPayload(
              data,
              trimValues,
              formatDate,
              format,
              emptyObjToNull
            )
          );
        } else if (typeof value === 'object') {
          if (formatDate && isDate) {
            data[key] = this.dateService.format(value, format);
          } else if (isDate) {
            data[key] = value;
          } else {
            this.filterPayload(
              data[key],
              trimValues,
              formatDate,
              format,
              emptyObjToNull
            );

            if (
              (emptyObjToNull || deleteParentKey) &&
              !Object.keys(value).length
            ) {
              data[key] = null;
            }
          }
        } else if (typeof value === 'string') {
          if (trimValues) {
            data[key] = value.trim();
          }
          if (formatDate && isDate) {
            data[key] = this.dateService.format(value, format);
          }
        }
      } else if (value !== 0 && deleteParentKey) {
        delete data[key];
      }
    });
  }

  /**
   * @description Replace object values with *replaceWith*
   * @param data JSON value - **Pass by reference!**
   */
  replaceValues(obj: { [key: string]: any }, replaceWith = '-') {
    Object.entries(obj).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((data) => this.replaceValues(data, replaceWith));
      } else if (value && typeof value === 'object') {
        this.replaceValues(value);
      } else if (!value && typeof value !== 'boolean') {
        obj[key] = replaceWith;
      }
    });
  }

  trimFormValues(data: { [key: string]: any }) {
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === 'string') {
        data[key] = data[key].trim();
      }
    });
  }

  getAvatarName(fullName: any) {
    const [initial, second] = fullName.split(' ');
    return `${initial?.slice(0, 1)}${(second || '').slice(0, 1)}`.trim();
  }

  joinStringsByDelimiter(delimiter: string, ...args: (string | undefined)[]) {
    return args
      .filter((arg) => arg)
      .join(delimiter)
      .trim();
  }

  getActivatedMenuItems(menuItem: any[]) {
    return menuItem.filter((menuItem: MenuItem) => menuItem.visible);
  }

  /**
   * @description Compare two dates
   * @param d1 Date 1
   * @param d2 Date 2
   * @returns If d1 > d2 then 1; If d1 < d2 then -1; If d1 == d2 then 0; If both are null then undefined
   */
  compareDates(d1?: Date | string, d2?: Date | string) {
    if (!d1 && !d2) return;
    if (!d1) return -1;
    if (!d2) return 1;

    const date1 = new Date(d1).getTime();
    const date2 = new Date(d2).getTime();

    if (date1 < date2) {
      return -1;
    } else if (date1 > date2) {
      return 1;
    } else {
      return 0;
    }
  }

  /**
   * @description Get only changed values from a form.
   * @param formItem The form to get changed values
   * @param updatedValues Pass an empty object - {} to store the changed values into
   */
  getFormChanges(
    formItem: FormGroup | FormArray | FormControl,
    updatedValues: any,
    name?: string
  ) {
    if (formItem instanceof FormControl) {
      if (name && formItem.dirty) {
        updatedValues[name] = formItem.value;
      }
    } else {
      for (const formControlName in formItem.controls) {
        if (formItem.controls.hasOwnProperty(formControlName)) {
          const formControl = (formItem.controls as any)[formControlName];

          if (formControl instanceof FormControl) {
            this.getFormChanges(formControl, updatedValues, formControlName);
          } else if (
            formControl instanceof FormArray &&
            formControl.dirty &&
            formControl.controls.length > 0
          ) {
            updatedValues[formControlName] = [];
            this.getFormChanges(formControl, updatedValues[formControlName]);
          } else if (formControl instanceof FormGroup && formControl.dirty) {
            updatedValues[formControlName] = {};
            this.getFormChanges(formControl, updatedValues[formControlName]);
          }
        }
      }
    }
  }

  gridDateComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
    const dateAsString = cellValue;
    if (!dateAsString) return -1;

    // User value
    const filterBy = filterLocalDateAtMidnight.getTime();
    // Iterate each row value
    const filterMe = new Date(cellValue).getTime();

    const isSameDate = this.dateService.isSame(filterBy, filterMe);

    if (filterBy === filterMe) {
      return 0;
    }

    if (filterMe < filterBy) {
      return -1;
    }

    if (filterMe > filterBy) {
      return 1;
    }

    if (isSameDate) {
      return 0;
    }

    return -1;
  }

  debounce(func: any, timeout = 400) {
    let timer: any;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  showToast(
    type: 'Error' | 'Success' | 'Info' | 'Warn',
    message?: string,
    summary?: string,
    life = 3000,
    clear = true
  ) {
    if (clear) {
      this.messageService.clear();
    }
    this.messageService.add({
      key: 'app-toast',
      severity: type.toLowerCase(),
      summary: summary || type,
      detail: message || 'An error occurred. Please try again later.',
      life,
    });
  }

  calculateTAT(dueDate: string, createdDate: string) {
    const diffDays = this.dateService.calculateDateDifference(
      createdDate,
      dueDate
    );
    const differenceInDays = diffDays > 0 ? diffDays : 0;
    return differenceInDays + (differenceInDays === 1 ? ' Day' : ' Days');
  }

  disableFormControls(form: FormGroup, controls: string[]) {
    controls.forEach((control: string) => {
      form.controls[control].disable();
    });
  }

  enableFormControls(form: FormGroup, controls: string[]) {
    controls.forEach((control: string) => {
      form.controls[control].enable();
    });
  }

  updateFormValidators(
    form: FormGroup,
    controls: string[] | string[][],
    validators: ValidatorFn | ValidatorFn[] = []
  ) {
    controls.forEach((control) => {
      const formControl = form.get(control);
      formControl?.setValidators(validators);
      formControl?.updateValueAndValidity();
    });
  }

  resetFormControls(form: FormGroup, controls: string[]) {
    controls.forEach((control: string) => {
      form.controls[control].reset();
    });
  }

  /**
   *
   * @description Merge 1-level objects (TODO: implement for nested objects)
   * @param source Default values of the object
   * @param target Overridden object from default values
   * @returns Merged object with target props as priority onto default object
   */
  merge(source: AnyObject<any>, target: AnyObject<any>) {
    const merged: AnyObject<any> = {};

    for (const key in source) {
      merged[key] = {};

      if (target.hasOwnProperty(key)) {
        if (
          typeof target[key] !== 'object' ||
          target[key] instanceof RegExp ||
          Array.isArray(target[key])
        ) {
          merged[key] = target[key];
        } else {
          merged[key] = {
            ...source[key],
            ...target[key],
          };
        }
      } else {
        merged[key] = source[key];
      }
    }

    return merged;
  }

  getActivatedRouteChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getActivatedRouteChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  getUrlWithoutParams() {
    const urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {};
    urlTree.fragment = null;
    return urlTree.toString();
  }

  getNewDate(value: string | Date | null | undefined) {
    return value ? new Date(value) : value;
  }

  toObject<T>(key: string | number, arr: T[]): { [lookupKey: typeof key]: T } {
    return arr.reduce((acc: any, v: T) => {
      acc[v[key as keyof T]] = v;
      return acc;
    }, {});
  }

  getThirdBusinessDay(date: Date) {
    const day = date.getDay();
    const daysToAdd = day === 5 ? 5 : day === 6 ? 4 : 3;
    const newDate = new Date(date);
    newDate.setDate(date.getDate() + daysToAdd);
    return newDate;
  }

  convertToISOString = (date: string | Date) => {
    if (!date) {
      return null;
    }

    if (typeof date !== 'string') {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        '0'
      )}-${String(date.getDate()).padStart(2, '0')}T${String(
        date.getHours()
      ).padStart(2, '0')}:${String(date.getMinutes()).padStart(
        2,
        '0'
      )}:${String(date.getSeconds()).padStart(2, '0')}Z`;
    } else {
      const [month, day, year] = date.split('/');
      return `${year}-${month}-${day}T00:00:00.000Z`;
    }
  };
}
