import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@outa-works/services';
import { MenuItem } from 'primeng/api';

export class OBasePanelMenuItem {
  constructor(private ref: ChangeDetectorRef) {}
  handleClick(event: any, item: any) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    item.expanded = !item.expanded;
    this.ref.detectChanges();

    if (!item.url && !item.routerLink) {
      event.preventDefault();
    }

    if (item.command) {
      item.command({
        originalEvent: event,
        item: item,
      });
    }
  }
}

@Component({
  selector: 'outa-works-panel-menu',
  templateUrl: './panelmenu.component.html',
  animations: [
    trigger('slideDown', [
      state('collapsed', style({ height: '0', opacity: '0' })),
      state('expanded', style({ height: '*', opacity: '1' })),
      transition('collapsed <=> expanded', [animate('200ms ease-in-out')]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./panelmenu.component.scss'],
})
export class OPanelMenuComponent {
  @Input() menuItem!: any;
  @Input() openedState!: boolean;
  @Output() sideBarCollapsed = new EventEmitter<boolean>();
  @HostBinding('class') class = 'p-element';

  public animating!: boolean;
  selectedSubMenuItem: any;

  constructor(
    private ref: ChangeDetectorRef,
    private router: Router,
    public commonService: CommonService
  ) {}

  public get opened(): boolean {
    return this.openedState;
  }

  visible(item: MenuItem) {
    return item.visible !== false;
  }

  collapseSideBar() {
    this.sideBarCollapsed.next(false);
  }
  onSubMenuItemClick(item: any, event?: Event): void {
    if (event) {
      event.stopPropagation();
    }
    this.selectedSubMenuItem = item;
    item.expanded = !item.expanded;
    if (item.routerLink && item.target) {
      window.open(item.routerLink, item.target);
    } else if (item.routerLink) {
      this.router.navigate([item.routerLink]);
    }
    this.collapseSideBar();
  }

  toggleSubItems(item: any, event: Event): void {
    event.stopPropagation();

    item.expanded = !item.expanded;
    item.manuallyOpened = item.expanded;

    this.ref.detectChanges();
  }

  handleMenuItemHover(item: any, isHovering: boolean): void {
    if (item.items && item.items.length > 0 && !item.manuallyOpened) {
      item.expanded = isHovering;
    }
  }
}
