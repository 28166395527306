import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '@environments';
import { map } from 'rxjs';

import { ProfileService } from '../services/profile.service';

export const loginGuard: CanActivateFn = () => {
  const router = inject(Router);
  const profileService = inject(ProfileService);

  return profileService.getUserInfo().pipe(
    map((response) => {
      if (response?.userName) {
        return true;
      } else {
        router.navigate([environment.BASE_URL]);
        return false;
      }
    })
  );
};
