import { DynamicField } from './dynamic-form';

export type SubForm = {
  id?: number;
  parentId?: number;
  type: 'sub-form';
  title: string;
  valueKey: string;
  fields: DynamicField[];
};

type MCQ = {
  id?: number;
  parentId?: number;
  type: 'mcq';
  title: string;
  valueKey: string;
  value?: any;
  // eslint-disable-next-line no-use-before-define
  options: Array<GpsOption>;
};

export type GpsOption = {
  label: string;
  value: any;
  dependents?: Array<SubForm | MCQ>;
};

export type GpsQuestion = MCQ | SubForm;
