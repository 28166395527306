interface ActivityExecutionContext {
  id: string;
  parentContextId?: string;
  scheduledActivityNodeId: string;
  ownerActivityNodeId?: string;
  properties: any;
  activityState: any;
}

interface WorkflowState {
  definitionId: string;
  definitionVersion: number;
  status: string;
  subStatus: string;
  bookmarks: any[];
  fault: any;
  completionCallbacks: any;
  activityExecutionContexts: ActivityExecutionContext[];
  output: any;
  properties: any;
  id: string;
}

export interface ElsaWorkflowDefinition {
  id: string;
  definitionId: string;
  definitionVersionId: string;
  version: number;
  workflowState: WorkflowState;
  status: string;
  subStatus: string;
  properties: any;
  fault: any;
  createdAt: string;
  lastExecutedAt: string;
}

export type ElsaWorkflowConnection =
  | {
      source: string;
      target: string;
      sourcePort: string;
      targetPort: string;
    }
  | {
      source: {
        activity: string;
        port: 'Done';
      };
      target: {
        activity: string;
        port: 'In';
      };
    };

interface MemoryReference {
  id: string;
}

interface Expression {
  type: string;
  value: string;
}

interface Path {
  typeName: string;
  expression: Expression;
  memoryReference: MemoryReference;
}

interface SupportedMethods {
  typeName: string;
  expression: Expression;
  memoryReference: MemoryReference;
}

interface Authorize {
  typeName: string;
  expression: Expression;
  memoryReference: MemoryReference;
}
interface CustomProperties {
  [key: string]: any;
}

interface Position {
  x: number;
  y: number;
}

interface Designer {
  position: Position;
}

interface Metadata {
  designer: Designer;
  displayText: string;
}

interface StatusCode {
  typeName: string;
  expression: Expression;
  memoryReference: MemoryReference;
}

interface Content {
  typeName: string;
  expression: Expression;
  memoryReference: MemoryReference;
}

interface ContentType {
  typeName: string;
  expression: Expression;
  memoryReference: MemoryReference;
}

interface ResponseHeaders {
  typeName: string;
  expression: Expression;
  memoryReference: MemoryReference;
}

interface UnmatchedStatusCode {
  type: string;
  version: number;
  id: string;
  metadata: Metadata;
  applicationProperties: any;
  activities: any[];
  connections: any[];
}

interface Method {
  typeName: string;
  expression: Expression;
  memoryReference: MemoryReference;
}

interface Expression9 {
  type: string;
  value: string;
}

interface MemoryReference9 {
  id: string;
}

interface RequestHeaders {
  typeName: string;
  expression: Expression9;
  memoryReference: MemoryReference9;
}

interface Expression10 {
  type: string;
  value: string;
}

interface MemoryReference10 {
  id: string;
}

interface Condition {
  typeName: string;
  expression: Expression10;
  memoryReference: MemoryReference10;
}

interface EventName {
  typeName: string;
  expression: Expression;
  memoryReference: MemoryReference;
}

interface Activity {
  path?: Path;
  supportedMethods?: SupportedMethods;
  authorize?: Authorize;
  id: string;
  type: string;
  version: number;
  canStartWorkflow: boolean;
  runAsynchronously: boolean;
  customProperties: CustomProperties;
  metadata: Metadata;
  statusCode?: StatusCode;
  content?: Content;
  contentType?: ContentType;
  responseHeaders?: ResponseHeaders;
  expectedStatusCodes?: any[];
  unmatchedStatusCode?: UnmatchedStatusCode;
  method?: Method;
  requestHeaders?: RequestHeaders;
  condition?: Condition;
  missingTypeName?: string;
  missingTypeVersion?: number;
  workflowDefinitionId?: string;
  latestAvailablePublishedVersion?: number;
  eventName?: EventName;
}

interface Root2 {
  root: any;
  type: string;
  version: number;
  id: string;
  metadata: any;
  applicationProperties: any;
  start: string;
  activities: Activity[];
  connections: ElsaWorkflowConnection[];
}

interface ElsaWorkflowCustomProperties {
  state?: string;
  caseType?: string;
  description?: string;
  libraryStepUniqueId?: string;
  condition?: string;
}

export interface ElsaWorkflowActivity {
  delay: any;
  fullName: string;
  stepName: any;
  id: string;
  definitionId: string;
  name: string;
  description?: string;
  createdAt: string;
  createdBy?: string;
  modifiedAt?: string;
  version: number;
  variables: any[];
  inputs: any[];
  outputs: any[];
  outcomes: any[];
  metadata: any;
  isLatest: boolean;
  isPublished: boolean;
  root: Root2;
  type: string;
  duration?: any;
  assignedTo?: any;
  dataverse?: string[];
  requiredDataverse?: string[];
  automated?: null | boolean;
  workflowDefinitionId?: string;
  workflowDefinitionVersionId?: string;
  isBuilt?: boolean;
  customProperties: ElsaWorkflowCustomProperties;
}

export type AssociatedWorkflow = {
  definitionId: string;
  usableAsActivity: boolean;
};

export interface ElsaWorkflow {
  id: string;
  definitionId: string;
  name: string;
  version: number;
  isLatest: boolean;
  isPublished: boolean;
  materializerName: string;
  usableAsActivity?: boolean;
  createdAt: string;
  customProperties: ElsaWorkflowCustomProperties;
  isDelete: boolean;
  isActive: boolean;
  createdBy?: string;
  modifiedBy?: string;
  modifiedAt?: string;
  associatedWorkflows: Array<AssociatedWorkflow>;
  activityCount: number;
}

export interface ElsaActivityProgress {
  [activityId: string]: { ActivityId: string; ExecutionCount: number };
}

export type WorkflowAssociatedDetails = {
  activityId: string;
  definitionId: string;
  caseType: string;
  activityCounts: number;
  isPublished: boolean;
  isActive: boolean;
  associatedWorkflows: AssociatedWorkflow[];
};

export type WorkflowWithAssociatedDetails = ElsaWorkflowActivity & {
  associatedDetails: WorkflowAssociatedDetails[];
};
