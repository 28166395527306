import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileType } from '@outa-works/models';
import { CommonService } from '@outa-works/services';
import { AuthService } from 'src/app/services/auth.service';

import { ProfileService } from '../../services/profile.service';
import { UserDataService } from '../../services/user-data.service';

import { SidebarService } from './sidebar.service';

@Component({
  selector: 'outa-works-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  private isOpen = true;
  profile: ProfileType = {};
  public isDockableSidebarOpened = false;

  public get opened(): boolean {
    return this.isOpen;
  }

  constructor(
    private authService: AuthService,
    public commonService: CommonService,
    private router: Router,
    private userDataService: UserDataService,
    public sidebarService: SidebarService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    this.getProfile();
  }

  onDockableSideBarCollapse() {
    this.sidebarService.selectedMenu.expanded = false;
    this.isDockableSidebarOpened = false;
  }

  getShortName(fullName?: string) {
    return fullName
      ?.split('.')
      .map((n) => n[0])
      .join('')
      .toLocaleUpperCase();
  }

  getProfile() {
    this.profileService.getUserInfo().subscribe((userinfo) => {
      this.profile.displayName = userinfo?.userName || '';
      this.profile.name = userinfo?.name || '';
      this.profile.role = userinfo?.role || '';
      if (this.profile.displayName.split('\\').length > 1) {
        this.profile.shortName = this.getShortName(
          this.profile.displayName?.split('\\')[1]
        );
      } else {
        this.profile.shortName = this.getShortName(this.profile.displayName);
      }

      this.userDataService.UserProfileData = this.profile;
    });
  }

  openDockableSideBar(item: any) {
    this.sidebarService.selectedMenu = item;

    if (item.command) {
      item.command();
      return;
    }

    this.sidebarService.menuItems().forEach((menuItem: any) => {
      if (menuItem !== this.sidebarService.selectedMenu) {
        menuItem.expanded = false;
      }
    });

    if (this.sidebarService.selectedMenu.items) {
      this.sidebarService.selectedMenu.expanded =
        !this.sidebarService.selectedMenu.expanded;
      this.isDockableSidebarOpened = this.sidebarService.selectedMenu.expanded;
    } else {
      this.isDockableSidebarOpened = false;
      if (this.sidebarService.selectedMenu.routerLink) {
        this.router.navigate([this.sidebarService.selectedMenu.routerLink], {
          state: { navId: 0 },
        });
      }
      this.sidebarService.selectedMenu.expanded = false;
    }
  }

  gotoDashboard() {
    this.router.navigate(['/']);
  }
}
