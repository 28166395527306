<aside
  [ngClass]="[
    'dockable-sidebar',
    'collapsable-sidebar',
    opened ? 'dockable-sidebar--opened' : 'dockable-sidebar--closed'
  ]"
>
  <div
    class="flex justify-content-end p-2 cursor-pointer white-space-nowrap"
    (click)="collapseSideBar()"
  >
    <i class="pi pi-angle-double-left text-white"></i>
  </div>
  @if (menuItem?.items) {
    <div class="text-white mt-3 text-base">
      <div class="px-3 white-space-nowrap">{{ menuItem.label }}</div>
      <ul id="panel-sub-item" class="sub-menu-list px-3">
        @for (
          item of commonService.getActivatedMenuItems(menuItem.items);
          track $index
        ) {
          <li
            (click)="onSubMenuItemClick(item, $event)"
            (mouseenter)="handleMenuItemHover(item, true)"
            (mouseleave)="handleMenuItemHover(item, false)"
          >
            <p
              [ngClass]="{
                'active-sub-menu': selectedSubMenuItem === item && !item?.items,
                'sub-menu-item-active': !item.items || item.items.length === 0,
                'sub-menu-item-inactive': item.items && item.items.length > 0
              }"
              class="font-medium p-2 d-flex align-items-center"
            >
              @if (item.items && item.items.length > 0) {
                <i
                  (click)="toggleSubItems(item, $event)"
                  class="pi pi-chevron-right margin-left cursor-pointer"
                  [ngClass]="{
                    'rotate-chevron': item.expanded
                  }"
                ></i>
              }
              {{ item.label }}
            </p>

            @if (item.expanded && item.items && item.items.length > 0) {
              <ul
                class="pl-4"
                [@slideDown]="item.expanded ? 'expanded' : 'collapsed'"
              >
                @for (
                  subItem of commonService.getActivatedMenuItems(item.items);
                  track $index
                ) {
                  <li
                    (click)="onSubMenuItemClick(subItem, $event)"
                    [ngClass]="{
                      'active-sub-menu': selectedSubMenuItem === subItem
                    }"
                    class="sub-menu-item-active font-medium p-2"
                    tabindex="0"
                  >
                    {{ subItem.label }}
                  </li>
                }
              </ul>
            }
          </li>
        }
      </ul>
    </div>
  }
</aside>
